// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentCard.typeB {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2rem;
    margin: 2rem 0 3rem 0;
    max-width: 70rem;
}
.contentCard.typeB .card {
    background-color: #FFF;
    border-radius: 1rem;
    padding: 1rem 2.5rem 2rem;
    color: #000;
    min-height: 27rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 0.875rem;
}
.contentCard.typeB .card:nth-child(2) {
    min-height: 32rem;
}
.contentCard.typeB svg {
    margin-bottom: 0.875rem;
}
.contentCard.typeB .title {
    font-size: 2rem;
    margin: 0 0 1rem;
}
@media (max-width: 769px) {
    .contentCard.typeB {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 2rem 0;
    }
    .contentCard.typeB .card {
        min-height: inherit;
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
    .contentCard.typeB .card:nth-child(2) {
        min-height: inherit;
    }
    .contentCard.typeB .boxTitle {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .contentCard.typeB svg {
        width: 60px;
        margin: 0;
    }
    .contentCard.typeB .title {
        font-size: 1.125rem;
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Main/FitYourNeeds/FitYourNeeds.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,mBAAmB;IACnB,SAAS;IACT,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,4CAA4C;IAC5C,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI;QACI,0BAA0B;QAC1B,WAAW;QACX,cAAc;IAClB;IACA;QACI,mBAAmB;QACnB,mBAAmB;QACnB,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,SAAS;IACb;IACA;QACI,WAAW;QACX,SAAS;IACb;IACA;QACI,mBAAmB;QACnB,SAAS;IACb;AACJ","sourcesContent":[".contentCard.typeB {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    align-items: center;\n    gap: 2rem;\n    margin: 2rem 0 3rem 0;\n    max-width: 70rem;\n}\n.contentCard.typeB .card {\n    background-color: #FFF;\n    border-radius: 1rem;\n    padding: 1rem 2.5rem 2rem;\n    color: #000;\n    min-height: 27rem;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);\n    font-size: 0.875rem;\n}\n.contentCard.typeB .card:nth-child(2) {\n    min-height: 32rem;\n}\n.contentCard.typeB svg {\n    margin-bottom: 0.875rem;\n}\n.contentCard.typeB .title {\n    font-size: 2rem;\n    margin: 0 0 1rem;\n}\n@media (max-width: 769px) {\n    .contentCard.typeB {\n        grid-template-columns: 1fr;\n        gap: 1.5rem;\n        margin: 2rem 0;\n    }\n    .contentCard.typeB .card {\n        min-height: inherit;\n        font-size: 0.875rem;\n        line-height: 1.125rem;\n    }\n    .contentCard.typeB .card:nth-child(2) {\n        min-height: inherit;\n    }\n    .contentCard.typeB .boxTitle {\n        display: flex;\n        align-items: center;\n        gap: 1rem;\n    }\n    .contentCard.typeB svg {\n        width: 60px;\n        margin: 0;\n    }\n    .contentCard.typeB .title {\n        font-size: 1.125rem;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
