// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    padding: 2rem;
}
.footer--content {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}
@media (max-width: 769px) {
    .footer--content {
        flex-direction: column;
    }
}
.footer--block {
    font-size: 0.875rem;
}
@media (max-width: 769px) {
    .footer--block:first-child {
        margin-bottom: 3rem;
    }
    .footer--block:last-child {
        margin-top: 3rem;
    }
}
.footer--menu {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #333;
}
.footer--menu li {
    line-height: 1.25rem;
    margin-bottom: 0.4rem;
}
.footer--menu li:first-child {
    font-weight: 600;
}
.footer--menu a {
    text-decoration: none;
    color: #333;
}
.footer--menu a:hover {
    color: #666;
}

.inner--footer {
    border-top: 1px solid #CCC;
    padding-top: 2.5rem;
}
@media (max-width: 769px) {
    .inner--footer {
        padding: 0;
        border: none;
    }
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,SAAS;AACb;AACA;IACI;QACI,sBAAsB;IAC1B;AACJ;AACA;IACI,mBAAmB;AACvB;AACA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,WAAW;AACf;AACA;IACI,oBAAoB;IACpB,qBAAqB;AACzB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;AACvB;AACA;IACI;QACI,UAAU;QACV,YAAY;IAChB;AACJ","sourcesContent":[".footer {\n    padding: 2rem;\n}\n.footer--content {\n    display: flex;\n    justify-content: space-around;\n    gap: 1rem;\n}\n@media (max-width: 769px) {\n    .footer--content {\n        flex-direction: column;\n    }\n}\n.footer--block {\n    font-size: 0.875rem;\n}\n@media (max-width: 769px) {\n    .footer--block:first-child {\n        margin-bottom: 3rem;\n    }\n    .footer--block:last-child {\n        margin-top: 3rem;\n    }\n}\n.footer--menu {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    color: #333;\n}\n.footer--menu li {\n    line-height: 1.25rem;\n    margin-bottom: 0.4rem;\n}\n.footer--menu li:first-child {\n    font-weight: 600;\n}\n.footer--menu a {\n    text-decoration: none;\n    color: #333;\n}\n.footer--menu a:hover {\n    color: #666;\n}\n\n.inner--footer {\n    border-top: 1px solid #CCC;\n    padding-top: 2.5rem;\n}\n@media (max-width: 769px) {\n    .inner--footer {\n        padding: 0;\n        border: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
