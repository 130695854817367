import React from 'react';

function ThribeeFavicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
      viewBox="0 0 18 22"
    >
      <path
        fill="#F8BB18"
        fillRule="evenodd"
        d="M2.637 19.163c-3.516-3.53-3.516-9.247 0-12.776L8.999 0s4.826 4.845 6.364 6.387c3.516 3.529 3.516 9.247 0 12.776a8.973 8.973 0 01-12.726 0zm8.48-4.255a3.023 3.023 0 000-4.264L8.998 8.52 6.88 10.644a3.023 3.023 0 000 4.264l2.118 2.126 2.117-2.126z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint0_linear_213_2780)"
        d="M2.637 19.163c-3.516-3.53-3.516-9.247 0-12.776L8.999 0a3.016 3.016 0 010 4.258L4.756 8.515a6.038 6.038 0 000 8.516 2.988 2.988 0 004.242 0l2.117-2.126a3.023 3.023 0 000-4.264L9 8.515v-.02c1.16-1.166 3.05-1.154 4.22.02h.02a6.038 6.038 0 010 8.516l-2.144 2.102c-2.33 2.342-6.128 2.37-8.458.03z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_213_2780"
          x1="0"
          x2="14.985"
          y1="20.895"
          y2="20.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7A300" />
          <stop offset="0.999" stopColor="#F78600" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ThribeeFavicon;
