import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import Header from 'pages/Homepage/Header/Header';
import ContactUs from 'pages/Homepage/Main/ContactUs/ContactUs';
import MarketingSolution from 'pages/Homepage/Main/MarketingSolution/MarketingSolution';
import FitYourNeeds from 'pages/Homepage/Main/FitYourNeeds/FitYourNeeds';
import WantKnowMore from 'pages/Homepage/Main/WantKnowMore/WantKnowMore';
import TrustTheExperts from 'pages/Homepage/Main/TrustTheExperts/TrustTheExperts';
import MarketLeader from 'pages/Homepage/Main/MarketLeader/MarketLeader';
import Footer from 'pages/Homepage/Footer/Footer';
import NextClassifiedsLevel from 'pages/Homepage/Main/NextClassifiedsLevel/NextClassifiedsLevel';
import { PageNavigationProvider } from 'pages/Homepage/PageNavigation';
import './Homepage.css';

export function Homepage() {
  const pageValue = useMemo(
    () => ({
      topAnchorId: 'contactForm',
    }),
    []
  );
  return (
    <PageNavigationProvider value={pageValue}>
      <Header />
      <main>
        <ContactUs />
        <MarketingSolution />
        <FitYourNeeds />
        <WantKnowMore />
        <TrustTheExperts />
        <MarketLeader />
        <NextClassifiedsLevel />
      </main>
      <Footer />
    </PageNavigationProvider>
  );
}
(function HomepagePage() {
  const rootElement = document.getElementById('homepage');

  if (!rootElement) {
    return;
  }

  ReactDOM.render(<Homepage />, rootElement);
})();
