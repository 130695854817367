// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border-radius: 1rem;
}

.video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.boxVideo {
    width: 100%;
    max-width: 44rem;
    margin-bottom: 3rem;
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Main/WantKnowMore/WantKnowMore.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,OAAO;IACP,MAAM;IACN,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".video {\n    overflow: hidden;\n    padding-bottom: 56.25%;\n    position: relative;\n    height: 0;\n    border-radius: 1rem;\n}\n\n.video iframe {\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n}\n\n.boxVideo {\n    width: 100%;\n    max-width: 44rem;\n    margin-bottom: 3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
