import React, { createContext, useContext } from 'react';
import Link from '@material-ui/core/Link';

const PageNavigation = createContext({ topAnchorId: 'js-back-to-top' });
export default PageNavigation;

export const {
  Consumer: PageNavigationConsumer,
  Provider: PageNavigationProvider,
} = PageNavigation;

export function BackToTopLink({ children }) {
  const { topAnchorId } = useContext(PageNavigation);
  return <Link href={`#${topAnchorId}`}>{children}</Link>;
}
