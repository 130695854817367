// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentCard.typeA {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 2rem 0 4rem 0;
    max-width: 70rem;
}
.contentCard.typeA .card {
    background-color: #FFF;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    color: #000;
    min-height: 21rem;
}
.contentCard.typeA svg {
    margin-bottom: 1.5rem;
    width: 2rem;
    height: auto;
}
.contentCard.typeA .title {
    font-size: 2rem;
    margin: 0 0 1rem;
}
@media (max-width: 769px) {
    .contentCard.typeA {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 2rem 0;
    }
    .contentCard.typeA .card {
        min-height: inherit;
        font-size: 0.8125rem;
        line-height: 1.125rem;
    }
    .contentCard.typeA svg {
        display: none;
    }
    .contentCard.typeA .title {
        font-size: 1.125rem;
        margin: 0 0 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Main/MarketingSolution/MarketingSolution.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI;QACI,0BAA0B;QAC1B,WAAW;QACX,cAAc;IAClB;IACA;QACI,mBAAmB;QACnB,oBAAoB;QACpB,qBAAqB;IACzB;IACA;QACI,aAAa;IACjB;IACA;QACI,mBAAmB;QACnB,gBAAgB;IACpB;AACJ","sourcesContent":[".contentCard.typeA {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 2rem;\n    margin: 2rem 0 4rem 0;\n    max-width: 70rem;\n}\n.contentCard.typeA .card {\n    background-color: #FFF;\n    border-radius: 1rem;\n    padding: 2rem 1.5rem;\n    color: #000;\n    min-height: 21rem;\n}\n.contentCard.typeA svg {\n    margin-bottom: 1.5rem;\n    width: 2rem;\n    height: auto;\n}\n.contentCard.typeA .title {\n    font-size: 2rem;\n    margin: 0 0 1rem;\n}\n@media (max-width: 769px) {\n    .contentCard.typeA {\n        grid-template-columns: 1fr;\n        gap: 1.5rem;\n        margin: 2rem 0;\n    }\n    .contentCard.typeA .card {\n        min-height: inherit;\n        font-size: 0.8125rem;\n        line-height: 1.125rem;\n    }\n    .contentCard.typeA svg {\n        display: none;\n    }\n    .contentCard.typeA .title {\n        font-size: 1.125rem;\n        margin: 0 0 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
