// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxContact {
    display: flex;
    align-items: center;
    gap: 2rem;
    max-width: 44rem;
    margin: 2rem;
}

.boxContact > h2 {
    font-size: 2.5rem;
    text-align: left;
}

.boxContact .buttonContact .btn--white {
    width: -moz-max-content;
    width: max-content;
}

@media (max-width: 769px) {
    .boxContact {
        margin: 0.5rem;
        flex-direction: column;
    }

    .boxContact > h2 {
        font-size: 1.5rem;
        text-align: center;
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Main/NextClassifiedsLevel/NextClassifiedsLevel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;QACd,sBAAsB;IAC1B;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;QAClB,SAAS;IACb;AACJ","sourcesContent":[".boxContact {\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    max-width: 44rem;\n    margin: 2rem;\n}\n\n.boxContact > h2 {\n    font-size: 2.5rem;\n    text-align: left;\n}\n\n.boxContact .buttonContact .btn--white {\n    width: -moz-max-content;\n    width: max-content;\n}\n\n@media (max-width: 769px) {\n    .boxContact {\n        margin: 0.5rem;\n        flex-direction: column;\n    }\n\n    .boxContact > h2 {\n        font-size: 1.5rem;\n        text-align: center;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
