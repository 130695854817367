import React from 'react';
import AdevintaLogo from 'pages/Homepage/images/AdevintaLogo';
import InmobiliareLogo from 'pages/Homepage/images/InmobiliareLogo';
import IdealistaLogo from 'pages/Homepage/images/IdealistaLogo';
import GroupeSelogerLogo from 'pages/Homepage/images/GroupeSelogerLogo';
import QuintoAndarLogo from 'pages/Homepage/images/QuintoAndarLogo';
import NaventLogo from 'pages/Homepage/images/NaventLogo';
import './TrustTheExperts.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function TrustTheExperts() {
  return (
    <section className="section section--lightblue">
      <div className="inner section--content">
        <h2
          dangerouslySetInnerHTML={{
            __html: trovitApp.i18nBIS('homepage_section6_title'),
          }}
        />
        <div className="contentLogos">
          <div className="logoExp">
            <AdevintaLogo />
          </div>
          <div className="logoExp">
            <InmobiliareLogo />
          </div>
          <div className="logoExp">
            <IdealistaLogo />
          </div>
          <div className="logoExp">
            <GroupeSelogerLogo />
          </div>
          <div className="logoExp">
            <QuintoAndarLogo />
          </div>
          <div className="logoExp">
            <NaventLogo />
          </div>
        </div>
        <div className="buttonExp">
          <BackToTopLink>
            <button className="btn btn--expand" type="button">
              {trovitApp.i18nBIS('homepage_button_demorequest')}
            </button>
          </BackToTopLink>
        </div>
        <div className="separador" />
        <div className="contentCard typeC">
          <div className="card">
            <div className="boxDesc">
              <div className="quote">“</div>
              <p>
                {trovitApp.i18nBIS('homepage_section7_testimonial1_text', {
                  bold: `<strong>${trovitApp.i18nBIS(
                    'homepage_section7_testimonial1_text_bold'
                  )}</strong>`,
                })}
              </p>
            </div>
            <div className="boxUser">
              <div className="foto">
                <img
                  src="/includes/js/pages/Homepage/images/SilvioFace.png"
                  alt=""
                />
              </div>
              <div
                className="user"
                dangerouslySetInnerHTML={{
                  __html: trovitApp.i18nBIS(
                    'homepage_section7_testimonial1_name'
                  ),
                }}
              />
            </div>
          </div>
          <div className="card">
            <div className="boxDesc">
              <div className="quote">“</div>
              <p>
                {trovitApp.i18nBIS('homepage_section7_testimonial2_text', {
                  bold: `<strong>${trovitApp.i18nBIS(
                    'homepage_section7_testimonial2_text_bold'
                  )}</strong>`,
                })}
              </p>
            </div>
            <div className="boxUser">
              <div className="foto">
                <img
                  src="/includes/js/pages/Homepage/images/DenisFace.png"
                  alt=""
                />
              </div>
              <div
                className="user"
                dangerouslySetInnerHTML={{
                  __html: trovitApp.i18nBIS(
                    'homepage_section7_testimonial2_name'
                  ),
                }}
              />
            </div>
          </div>
          <div className="card">
            <div className="boxDesc">
              <div className="quote">“</div>
              <p>
                {trovitApp.i18nBIS('homepage_section7_testimonial3_text', {
                  bold: `<strong>${trovitApp.i18nBIS(
                    'homepage_section7_testimonial3_text_bold'
                  )}</strong>`,
                })}
              </p>
            </div>
            <div className="boxUser">
              <div className="foto">
                <img
                  src="/includes/js/pages/Homepage/images/AgustinaFace.jpg"
                  alt=""
                />
              </div>
              <div
                className="user"
                dangerouslySetInnerHTML={{
                  __html: trovitApp.i18nBIS(
                    'homepage_section7_testimonial3_name'
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
