import React from 'react';

export default function TrovitLogo() {
  return (
    <svg
      className="TrovitLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="26"
      fill="none"
      viewBox="0 0 68 26"
    >
      <path
        fill="#2E374F"
        fillRule="evenodd"
        d="M18.334 16.53c0-4.742 3.676-8.592 8.222-8.592 4.545 0 8.22 3.85 8.22 8.591s-3.675 8.591-8.22 8.591c-4.546 0-8.222-3.85-8.222-8.59zm4.698 0c0 2.022 1.588 3.675 3.524 3.675 1.935 0 3.523-1.653 3.523-3.676 0-2.022-1.588-3.675-3.523-3.675-1.936 0-3.524 1.653-3.524 3.675z"
        clipRule="evenodd"
      />
      <path fill="#2E374F" d="M52.481 8.265H57.07V24.773H52.481z" />
      <path
        fill="#2E374F"
        d="M52.22 3.24A2.548 2.548 0 0154.765.696a2.548 2.548 0 012.544 2.544 2.548 2.548 0 01-2.544 2.545A2.548 2.548 0 0152.22 3.24M64.29 12.158h3.133V8.265H64.29V3.523l-4.59 1.11v14.115c0 2.61.37 6.307 5.982 6.307a14.2 14.2 0 001.74-.109v-3.632h-1.218c-1 0-1.914-.544-1.914-2.11v-7.046zM4.59 12.158H7.72V8.265H4.589V3.523L0 4.611v14.115c0 2.61.37 6.307 5.981 6.307.827 0 1.61-.087 1.74-.109v-3.61H6.503c-1.022 0-1.914-.544-1.914-2.11v-7.046zM42.65 18.878l3.285-10.613h5.089L45.52 23.119c-.456 1.131-1.566 2.001-2.892 2.001-1.327 0-2.415-.87-2.893-2l-5.48-14.834h5.11l3.284 10.592M16.899 7.938c-3.871 0-7.025 3.154-7.025 7.025v9.81h4.59v-9.136c0-2.044 1.26-3.153 3.153-3.153h.413V7.938h-1.131z"
      />
    </svg>
  );
}
