import React from 'react';
import BullsEyeIcon from 'pages/Homepage/images/BullsEyeIcon';
import MegaphoneIcon from 'pages/Homepage/images/MegaphoneIcon';
import ScreenWithMetricsIcon from 'pages/Homepage/images/ScreenWithMetricsIcon';
import './FitYourNeeds.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function FitYourNeeds() {
  return (
    <section className="section">
      <div className="inner section--content">
        <h2
          dangerouslySetInnerHTML={{
            __html: trovitApp.i18nBIS('homepage_section4_title'),
          }}
        />
        <div className="contentCard typeB">
          <div className="card">
            <div className="boxTitle">
              <BullsEyeIcon />
              <h4 className="title">
                {trovitApp.i18nBIS('homepage_section4_firstbox_title')}
              </h4>
            </div>
            <div className="boxDesc">
              <p>
                {trovitApp.i18nBIS('homepage_section4_firstbox_explanation')}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="boxTitle">
              <MegaphoneIcon />
              <h4 className="title">
                {trovitApp.i18nBIS('homepage_section4_secondbox_title')}
              </h4>
            </div>
            <div className="boxDesc">
              <p>
                {trovitApp.i18nBIS('homepage_section4_secondbox_explanation')}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="boxTitle">
              <ScreenWithMetricsIcon />
              <h4 className="title">
                {trovitApp.i18nBIS('homepage_section4_thirdbox_title')}
              </h4>
            </div>
            <div className="boxDesc">
              <p>
                {trovitApp.i18nBIS('homepage_section4_thirdbox_explanation')}
              </p>
            </div>
          </div>
        </div>
        <BackToTopLink>
          <button className="btn btn--expand" type="button">
            {trovitApp.i18nBIS('homepage_button_demorequest')}
          </button>
        </BackToTopLink>
      </div>
    </section>
  );
}
