// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAppBar-root.AppBar {
    display: block;
}

.MuiAppBar-root.AppBar--colorDefault {
    background-color: var(--palette-thribee-white);
    border-bottom-color: var(--palette-gray-separator);
}

.MuiToolbar-root.Toolbar--regular {
    height: 6rem;
}

.header--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
}

@media (max-width: 769px) {
    .header--content {
        padding: 1.5rem;
    }

    .header--content .logo img {
        height: 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,8CAA8C;IAC9C,kDAAkD;AACtD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,oBAAoB;AACxB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".MuiAppBar-root.AppBar {\n    display: block;\n}\n\n.MuiAppBar-root.AppBar--colorDefault {\n    background-color: var(--palette-thribee-white);\n    border-bottom-color: var(--palette-gray-separator);\n}\n\n.MuiToolbar-root.Toolbar--regular {\n    height: 6rem;\n}\n\n.header--content {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 1.5rem 2rem;\n}\n\n@media (max-width: 769px) {\n    .header--content {\n        padding: 1.5rem;\n    }\n\n    .header--content .logo img {\n        height: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
