import React from 'react';

function ScreenWithMetricsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="121"
      fill="none"
      viewBox="0 0 120 121"
    >
      <path fill="transparent" d="M0 0.125H120V120.125H0z" />
      <path
        stroke="#76B6FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M10.8 25.401c1.848-.289 5.197 0 5.197-7.276.057 2.483.104 7.276 5.37 7.276-2.252.116-5.37 1.247-5.37 6.93.23-2.484-.693-6.064-5.197-6.93z"
      />
      <path
        stroke="#76B6FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M102.203 94.065c1.294-.204 3.638 0 3.638-5.141.04 1.754.073 5.141 3.759 5.141-1.576.082-3.759.882-3.759 4.897.162-1.755-.485-4.285-3.638-4.897z"
      />
      <path
        fill="#F7A900"
        d="M45.11 99.153h29.475v2.601H45.11v-2.6zM97.556 83.134H22.139a1.3 1.3 0 01-1.3-1.3V33.785a4.847 4.847 0 014.834-4.847h68.35a4.845 4.845 0 014.833 4.847v48.049a1.302 1.302 0 01-1.3 1.3zm-74.117-2.6h72.816V33.784a2.244 2.244 0 00-2.233-2.246h-68.35a2.243 2.243 0 00-2.233 2.246v46.748z"
      />
      <path
        fill="#F7A900"
        d="M94 90.158H25.673a4.85 4.85 0 01-4.834-4.848v-3.476a1.302 1.302 0 011.3-1.3h75.417a1.298 1.298 0 011.3 1.3v3.455a4.868 4.868 0 01-4.857 4.87zm-70.56-7.024v2.176a2.243 2.243 0 002.233 2.248H94a2.266 2.266 0 002.256-2.27v-2.154H23.44z"
      />
      <path fill="#F7A900" d="M58.547 88.858h2.6v11.596h-2.6V88.858z" />
      <path
        fill="#76B6FF"
        d="M75.742 70.431h-4.667c-.292 0-.572-.119-.778-.331a1.15 1.15 0 01-.323-.802V53.773c0-.437.245-.835.628-1.023l4.667-2.285a1.075 1.075 0 011.06.064c.32.208.514.57.514.96v17.809c0 .3-.116.589-.323.801a1.086 1.086 0 01-.778.332zm-3.566-2.266h2.464V53.282l-2.464 1.207v13.676zM65.619 70.431h-5.026c-.315 0-.616-.12-.839-.334a1.118 1.118 0 01-.346-.807V58.025c0-.44.263-.842.676-1.031l5.025-2.302a1.226 1.226 0 011.143.066c.345.21.553.573.553.965V69.29c0 .302-.125.593-.348.807a1.21 1.21 0 01-.838.334zm-3.84-2.282h2.654v-10.62l-2.654 1.217v9.403zM86.224 70.431H81.2c-.314 0-.616-.12-.839-.333a1.113 1.113 0 01-.347-.803V49.027c0-.439.263-.838.677-1.027l5.025-2.29a1.229 1.229 0 011.142.065c.344.208.553.571.553.961v22.56c0 .3-.125.59-.347.803a1.213 1.213 0 01-.839.332zm-3.84-2.273h2.655V48.536l-2.654 1.21v18.412z"
      />
      <path
        fill="#F7A900"
        d="M42.5 70.96a11.14 11.14 0 01-9.61-5.558 11.063 11.063 0 010-11.074 11.14 11.14 0 019.61-5.559 1.14 1.14 0 011.143 1.14v8.818h8.81a1.144 1.144 0 011.143 1.138 11.069 11.069 0 01-3.246 7.834 11.146 11.146 0 01-7.85 3.26zm-1.142-19.84a8.852 8.852 0 00-5.644 3.053 8.795 8.795 0 00.486 11.916 8.863 8.863 0 0011.954.53 8.807 8.807 0 003.083-5.615H42.5a1.143 1.143 0 01-1.142-1.139v-8.744z"
      />
      <path
        fill="#F7A900"
        d="M57.217 56.166h-9.883a1.137 1.137 0 01-1.135-1.13v-9.89a1.13 1.13 0 011.135-1.132 11.064 11.064 0 017.794 3.24 10.996 10.996 0 013.223 7.781 1.13 1.13 0 01-1.134 1.131zm-8.749-2.262h7.541a8.752 8.752 0 00-2.486-5.052 8.8 8.8 0 00-5.055-2.503v7.555z"
      />
    </svg>
  );
}

export default ScreenWithMetricsIcon;
