import React from 'react';

function MegaphoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="121"
      fill="none"
      viewBox="0 0 120 121"
    >
      <path fill="transparent" d="M0 0.125H120V120.125H0z" />
      <path
        fill="#F7A900"
        d="M81.981 51.744c-3.214-13.3-9.505-27.619-15.72-27.619-.308 0-.615.037-.91.108-1.355.327-2.433 1.359-3.225 3.034-.084.067-.176.117-.245.206-3.479 4.448-15.938 19.138-30.15 22.573L20.87 52.85c-.043.01-.084.023-.127.036a13.484 13.484 0 00-7.944 5.93 13.278 13.278 0 00-1.618 10.179 13.286 13.286 0 006.089 8.316c3.064 1.849 6.688 2.398 10.201 1.55l2.252-.543h.002l2.71-.655 8.985 18.464a6.773 6.773 0 009.041 3.122 6.772 6.772 0 003.12-9.041l-7.266-14.93c12.973-.11 26.22 5.92 29.248 7.388 1.084.808 2.182 1.255 3.278 1.255.307 0 .614-.036.91-.108 2.869-.693 4.517-4.507 4.768-11.03.227-5.859-.674-13.33-2.538-21.039v.001zM26.794 76.067c-2.77.67-5.627.237-8.041-1.219a10.428 10.428 0 01-4.779-6.53 10.423 10.423 0 011.27-7.993 10.632 10.632 0 016.493-4.734l.958-.232 4.955 20.5-.856.208zm22.409 20.596a3.893 3.893 0 01-5.198-1.795l-3.261-6.7 7.087-3.201 3.165 6.5a3.892 3.892 0 01-1.793 5.196zm-2.63-14.283l-7.087 3.2-4.19-8.61 3.328-.805a31.53 31.53 0 014.566-.737l3.383 6.952zm-8.625-9.008l-7.503 1.812-4.955-20.501 6.917-1.676C44.359 50.118 54.986 39.882 60.7 33.433c-.051.587-.093 1.193-.118 1.832-.118 3.05.076 6.543.547 10.274.007.054-.007.105.006.158.004.018.018.03.022.048a98.786 98.786 0 001.964 10.559 100.68 100.68 0 003.174 10.576c.006.068-.009.132.007.2.048.195.14.362.255.514 1.418 3.802 3.037 7.29 4.764 10.063-7.732-3.066-21.349-7.194-33.375-4.285zm26.256-26.565c2.166.597 4.738 3.553 5.827 8.055 1.08 4.474.165 8.255-1.474 9.797a96.378 96.378 0 01-2.64-9.033 97.831 97.831 0 01-1.713-8.819zM81.648 72.67c-.21 5.387-1.442 8.075-2.573 8.348a.972.972 0 01-.234.026c-.255 0-.538-.079-.845-.23a1.41 1.41 0 00-.453-.377c-.06-.031-.215-.112-.447-.227-2.08-1.716-4.888-6.055-7.496-12.728 3.24-1.99 4.652-7.396 3.226-13.298-1.434-5.931-5.18-10.115-9-10.364-.333-3.055-.468-5.919-.371-8.447.208-5.386 1.44-8.072 2.57-8.346a.973.973 0 01.235-.026c2.773 0 8.97 9.052 12.926 25.417 1.804 7.459 2.677 14.65 2.462 20.25v.002z"
      />
      <path
        fill="#76B6FF"
        d="M92.96 50.86a1.438 1.438 0 01-.31-2.835l9.393-2.305a1.438 1.438 0 01.685 2.792l-9.393 2.305c-.125.03-.25.045-.374.042zM85.846 35.148a1.437 1.437 0 01-.869-2.531l8.47-7.204a1.438 1.438 0 011.862 2.191l-8.47 7.203a1.422 1.422 0 01-.993.341zM105.607 70.634a1.427 1.427 0 01-.589-.022L94.606 68.04a1.436 1.436 0 01-1.05-1.74 1.431 1.431 0 011.739-1.05l10.412 2.572a1.437 1.437 0 01-.1 2.812z"
      />
    </svg>
  );
}

export default MegaphoneIcon;
