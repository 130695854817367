import React from 'react';
import ThribeeFavicon from 'pages/Homepage/images/ThribeeFavicon';
import './MarketingSolution.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function MarketingSolution() {
  return (
    <section className="section section--blue">
      <div className="inner section--content">
        <h2>{trovitApp.i18nBIS('homepage_section2_title')}</h2>
        <div className="contentCard typeA">
          <div className="card">
            <ThribeeFavicon />
            <h4 className="title">
              {trovitApp.i18nBIS('homepage_section2_firstbox_title')}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: trovitApp.i18nBIS(
                  'homepage_section2_firstbox_explanation'
                ),
              }}
            />
          </div>
          <div className="card">
            <ThribeeFavicon />
            <h4 className="title">
              {trovitApp.i18nBIS('homepage_section2_secondbox_title')}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: trovitApp.i18nBIS(
                  'homepage_section2_secondbox_explanation'
                ),
              }}
            />
          </div>
          <div className="card">
            <ThribeeFavicon />
            <h4 className="title">
              {trovitApp.i18nBIS('homepage_section2_thirdbox_title')}
            </h4>
            <p>{trovitApp.i18nBIS('homepage_section2_thirdbox_explanation')}</p>
          </div>
        </div>
        <BackToTopLink>
          <button className="btn btn--expand" type="button">
            {trovitApp.i18nBIS('homepage_button_contactus')}
          </button>
        </BackToTopLink>
      </div>
    </section>
  );
}
