import React from 'react';
import './NextClassifiedsLevel.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function NextClassifiedsLevel() {
  return (
    <section className="section section--blue">
      <div className="inner section--content">
        <div className="boxContact">
          <h2>{trovitApp.i18nBIS('homepage_section8_title')}</h2>
          <div className="buttonContact">
            <BackToTopLink>
              <button className="btn btn--white" type="button">
                {trovitApp.i18nBIS('homepage_form_title')}
              </button>
            </BackToTopLink>
          </div>
        </div>
      </div>
    </section>
  );
}
