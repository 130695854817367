import React from 'react';
import './WantKnowMore.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function WantKnowMore() {
  return (
    <section className="section section--blue">
      <div className="inner section--content">
        <h2>{trovitApp.i18nBIS('homepage_section5_title')}</h2>
        <p className="subtitle">
          {trovitApp.i18nBIS('homepage_section5_subtitle')}
        </p>
        <div className="boxVideo">
          <div className="video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/R1k6agKAnEc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
        <BackToTopLink>
          <button className="btn btn--expand" type="button">
            {trovitApp.i18nBIS('homepage_button_contactus')}
          </button>
        </BackToTopLink>
      </div>
    </section>
  );
}
