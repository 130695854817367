import React from 'react';

function BullsEyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="121"
      fill="none"
      viewBox="0 0 120 121"
    >
      <path fill="transparent" d="M0 0.125H120V120.125H0z" />
      <path
        fill="#F7A900"
        d="M52.086 105.124c-9.905 0-19.223-3.863-26.223-10.862-7-7-10.863-16.318-10.863-26.223 0-9.905 3.863-19.223 10.863-26.223 13.134-13.134 34.118-14.51 48.797-3.199.68.526.803 1.483.278 2.164a1.534 1.534 0 01-2.163.278C59.3 30.69 40.078 31.957 28.04 43.994c-6.428 6.428-9.952 14.958-9.952 24.044s3.54 17.615 9.952 24.044c6.412 6.427 14.957 9.951 24.043 9.951 9.087 0 17.616-3.539 24.044-9.951 12.038-12.038 13.304-31.276 2.936-44.734a1.545 1.545 0 01.278-2.163 1.531 1.531 0 012.163.278c11.312 14.694 9.936 35.68-3.198 48.797-7.014 7.016-16.316 10.864-26.221 10.864z"
      />
      <path
        fill="#F7A900"
        d="M52.086 85.036a16.875 16.875 0 01-12.022-4.975c-6.629-6.63-6.629-17.415 0-24.044a16.937 16.937 0 0119.887-3.06 1.54 1.54 0 01.649 2.087 1.54 1.54 0 01-2.086.649c-5.408-2.828-11.945-1.823-16.271 2.503a13.817 13.817 0 00-4.08 9.827c0 3.709 1.453 7.2 4.08 9.828a13.817 13.817 0 009.827 4.08c3.709 0 7.2-1.453 9.827-4.08a13.857 13.857 0 002.488-16.302 1.54 1.54 0 01.649-2.086 1.553 1.553 0 012.086.649c3.477 6.629 2.256 14.633-3.028 19.918-3.199 3.244-7.464 5.006-12.007 5.006z"
      />
      <path
        fill="#76B6FF"
        d="M52.086 72.675c-1.19 0-2.38-.449-3.276-1.36a4.635 4.635 0 010-6.552 4.635 4.635 0 016.552 0 4.635 4.635 0 010 6.552 4.598 4.598 0 01-3.276 1.36zm-1.097-3.54a1.538 1.538 0 002.178 0 1.557 1.557 0 000-2.194 1.538 1.538 0 00-2.178 0 1.557 1.557 0 000 2.195z"
      />
      <path
        fill="#F7A900"
        d="M52.086 95.837c-7.123 0-14.247-2.704-19.671-8.127-10.848-10.848-10.848-28.494 0-39.34C41.964 38.82 56.92 37.49 67.97 45.2c.695.495.865 1.453.386 2.148a1.556 1.556 0 01-2.148.387c-9.827-6.861-23.117-5.671-31.6 2.812-9.643 9.643-9.643 25.326 0 34.968 9.642 9.643 25.325 9.643 34.967 0 8.484-8.483 9.673-21.788 2.813-31.614a1.54 1.54 0 01.386-2.148 1.554 1.554 0 012.148.386c7.711 11.063 6.382 26.006-3.167 35.556-5.424 5.439-12.547 8.143-19.671 8.143v-.002z"
      />
      <path
        fill="#76B6FF"
        d="M52.086 69.584c-.402 0-.788-.154-1.097-.448a1.538 1.538 0 010-2.179L83.037 34.91a1.538 1.538 0 012.179 0 1.538 1.538 0 010 2.178L53.167 69.136a1.545 1.545 0 01-1.081.448z"
      />
      <path
        fill="#76B6FF"
        d="M91.458 38.201c-.124 0-.247 0-.371-.015L83.9 37.63a1.555 1.555 0 01-1.421-1.422l-.556-7.17a4.651 4.651 0 011.344-3.646l9.812-9.812a1.54 1.54 0 011.545-.386c.541.17.959.618 1.067 1.174l1.344 6.721 6.722 1.345a1.549 1.549 0 01.788 2.612l-9.812 9.812a4.673 4.673 0 01-3.276 1.343zm-5.98-3.554l5.856.464c.449.03.897-.124 1.221-.448l7.773-7.773-4.915-.973a1.516 1.516 0 01-1.205-1.205l-.973-4.899-7.772 7.773a1.552 1.552 0 00-.449 1.22l.464 5.841z"
      />
    </svg>
  );
}

export default BullsEyeIcon;
