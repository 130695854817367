import React from 'react';

export default function IdealistaLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="555"
      height="160"
      viewBox="0 0 555 160"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M90 115V70H60V55h30V25h15v105H60v-15h30zm375-60h15v15h-15v45h-15V70h-15V55h15V25h15v30zM345 70v60h-15V70h-15V55h30v15zM30 70v60H15V70H0V55h30v15zm210 45v-15h-30V85h30V70h15v60h-45v-15h30zm300 0v-15h-30V85h30V70h15v60h-45v-15h30zm-375-15h-30v15h-15V70h15v15h30V70h15v30h-15zm135-60v90h-15V40h-15V25h30v15zm75 15h45v15h-45V55zm-15 15h15v15h-15V70zm15 15h30v15h-30V85zm30 15h15v15h-15v-15zm-45 15h45v15h-45v-15zm-225 0h30v15h-30v-15zm0-60h30v15h-30V55zm375 0h30v15h-30V55zm-15 45h15v15h-15v-15zM210 55h30v15h-30V55zm-15 45h15v15h-15v-15zM15 25h15v15H15V25zm315 0h15v15h-15V25zm135 90h15v15h-15v-15zM45 70h15v45H45V70z"
        />
      </g>
    </svg>
  );
}
