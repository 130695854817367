import React from 'react';
import WorldMap from 'pages/Homepage/images/WorldMap';
import './MarketLeader.css';
import { BackToTopLink } from 'pages/Homepage/PageNavigation';

export default function MarketLeader() {
  return (
    <section className="section">
      <div className="inner section--content">
        <h2
          dangerouslySetInnerHTML={{
            __html: trovitApp.i18nBIS('homepage_section7_title'),
          }}
        />
        <div className="stats-content">
          <div className="stats">
            <div className="num">4</div>
            <div className="desc">
              {trovitApp.i18nBIS('homepage_section7_verticals')}
            </div>
          </div>
          <div className="stats">
            <div className="num">+60</div>
            <div className="desc">
              {trovitApp.i18nBIS('homepage_section7_countries')}
            </div>
          </div>
          <div className="stats">
            <div className="num">+900</div>
            <div className="desc">
              {trovitApp.i18nBIS('homepage_section7_partners')}
            </div>
          </div>
          <div className="stats">
            <div className="num">+200M</div>
            <div className="desc">
              {trovitApp.i18nBIS('homepage_section7_listings')}
            </div>
          </div>
          <div className="ButtonStats">
            <BackToTopLink>
              <button className="btn" type="button">
                {trovitApp.i18nBIS('homepage_button_demorequest')}
              </button>
            </BackToTopLink>
          </div>
        </div>
        <div className="worldmap">
          <WorldMap />
        </div>
      </div>
    </section>
  );
}
