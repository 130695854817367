import React, { useContext } from 'react';
import ThribeeLogo from 'pages/Homepage/images/ThribeeLogo';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PageNavigation from 'pages/Homepage/PageNavigation';
import './Header.css';

export default function Header({ children }) {
  const appBarClasses = {
    root: 'AppBar',
    colorDefault: 'AppBar--colorDefault',
  };
  const { topAnchorId } = useContext(PageNavigation);
  return (
    <>
      <AppBar
        position="fixed"
        classes={appBarClasses}
        color="default"
        variant="outlined"
      >
        <div className="inner header--content">
          <div className="logo">
            <ThribeeLogo />
          </div>
          <div className="nav">
            {children || (
              <a href="/index.php/cod.login" className="btn btn--flat">
                {trovitApp.i18nBIS('homepage_button_login')}
              </a>
            )}
          </div>
        </div>
      </AppBar>
      <Toolbar id={topAnchorId} classes={{ regular: 'Toolbar--regular' }} />
    </>
  );
}
