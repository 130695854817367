// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worldmap {
    margin: 3rem 0;
}

.worldmap > svg{
    max-width: 100%;
    height: auto;
}

@media (max-width: 769px) {
    .worldmap {
        margin: 1.5rem 0;
    }
}

.stats-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    align-items: center;
}

.stats-content .num {
    font-size: 3.5rem;
    font-weight: 700;
    color: #3181F7;
    letter-spacing: -0.02rem;
}

@media (max-width: 1024px) {
    .stats-content .num {
        font-size: 2.5rem;
    }
}

@media (max-width: 769px) {
    .stats-content .num {
        font-size: 2rem;
    }
}

.stats-content .desc {
    font-size: 1.25rem;
    font-weight: 500;
}

.stats-content .ButtonStats {
    padding: 0 1rem;
    width: -moz-max-content;
    width: max-content;
}

@media (max-width: 769px) {
    .stats-content {
        grid-template-columns: repeat(2, 1fr);
    }

    .stats-content .ButtonStats {
        grid-column: span 2;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./js/pages/Homepage/Main/MarketLeader/MarketLeader.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI;QACI,qCAAqC;IACzC;;IAEA;QACI,mBAAmB;QACnB,WAAW;IACf;AACJ","sourcesContent":[".worldmap {\n    margin: 3rem 0;\n}\n\n.worldmap > svg{\n    max-width: 100%;\n    height: auto;\n}\n\n@media (max-width: 769px) {\n    .worldmap {\n        margin: 1.5rem 0;\n    }\n}\n\n.stats-content {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    gap: 2rem;\n    align-items: center;\n}\n\n.stats-content .num {\n    font-size: 3.5rem;\n    font-weight: 700;\n    color: #3181F7;\n    letter-spacing: -0.02rem;\n}\n\n@media (max-width: 1024px) {\n    .stats-content .num {\n        font-size: 2.5rem;\n    }\n}\n\n@media (max-width: 769px) {\n    .stats-content .num {\n        font-size: 2rem;\n    }\n}\n\n.stats-content .desc {\n    font-size: 1.25rem;\n    font-weight: 500;\n}\n\n.stats-content .ButtonStats {\n    padding: 0 1rem;\n    width: -moz-max-content;\n    width: max-content;\n}\n\n@media (max-width: 769px) {\n    .stats-content {\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .stats-content .ButtonStats {\n        grid-column: span 2;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
