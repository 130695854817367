import React from 'react';

export default function MitulaLogo() {
  return (
    <svg
      className="MitulaLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="22"
      fill="none"
      viewBox="0 0 105 22"
    >
      <path
        fill="#FF5050"
        fillRule="evenodd"
        d="M89.909 16.008c-2.818 0-5.103-2.27-5.103-5.07 0-2.801 2.285-5.072 5.103-5.072 2.817 0 5.102 2.27 5.102 5.071 0 2.8-2.284 5.071-5.102 5.071zm14.428-5.07l-7.12-7.078A10.203 10.203 0 0089.908.796c-5.636 0-10.204 4.54-10.204 10.141S84.273 21.08 89.909 21.08c2.865 0 5.455-1.175 7.309-3.066l7.119-7.076z"
        clipRule="evenodd"
      />
      <path
        fill="#552D73"
        fillRule="evenodd"
        d="M60.537 21.25c-2.447 0-4.088-1.173-4.088-4.006V.391h3.627v16.252c0 .858.202 1.43 1.44 1.43.176 0 .281-.008.374-.016.074-.006.141-.012.23-.012v3.005l-.3.06c-.479.1-.676.14-1.283.14zm-33.666-.172h-3.656V6.2h3.656v14.878zm.087-16.938H23.1V.449h3.857v3.69zM16.901 6.6v14.478H20.5V.792h-4.75l-5.384 14.45L5.012.791H.002v20.286h3.6V6.428l5.267 14.364h2.735L16.901 6.6zM36.245 21.28c-3.023 0-4.52-1.717-4.52-4.579V9.147H28.99V6.2h2.735V2.423l3.599-.887V6.2h3.77v2.947h-3.77v7.239c0 1.144.518 1.63 1.67 1.63.748 0 1.352-.028 2.331-.142v3.004c-1.007.258-2.015.4-3.08.4zm14.412-2.146l.259 1.945h2.994V6.2H50.31v7.868c0 1.202-.23 2.547-1.094 3.348-.432.43-1.008.744-1.842.744-1.9 0-2.591-1.088-2.591-2.833V6.2h-3.6v9.928c0 3.176 1.987 5.322 5.183 5.322 1.9 0 3.57-.858 4.29-2.317zm18.568-4.378l3.8-.286v.687c0 2.203-1.583 3.404-3.829 3.404-1.122 0-2.13-.715-2.13-1.86 0-1.201 1.065-1.86 2.16-1.945zm4.29 4.463c.115 1.116.979 2.09 2.533 2.09.846 0 1.167-.138 1.747-.385l.038-.016v-2.633s-.23.057-.46.057c-.605 0-.835-.257-.835-.944v-5.522c0-1.001-.058-2.06-.432-3.004-.864-2.232-3.052-3.033-5.614-3.033-3.34 0-6.017 1.488-6.218 4.835h3.483c.087-1.373 1.065-2.117 2.735-2.117 2.044 0 2.533.944 2.533 2.346v.916l-4.404.315c-2.85.2-5.067 1.83-5.067 4.663 0 2.432 1.842 4.664 5.412 4.664 2.13 0 3.858-1.03 4.549-2.232z"
        clipRule="evenodd"
      />
    </svg>
  );
}
