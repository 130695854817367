import React from 'react';

export default function QuintoAndarLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="26"
      fill="none"
      viewBox="0 0 184 26"
    >
      <g clipPath="url(#clip0_611_1811)">
        <path
          fill="#3957BD"
          fillRule="evenodd"
          d="M0 24.469V.459h24.103v24.01h-3.579l-8.594-8.56 2.547-2.536 6.047 6.024V4.023H3.59v16.858h7.322v3.588H0z"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          d="M50.673 11.964a9.94 9.94 0 00-1.725-5.513 9.996 9.996 0 00-4.506-3.634A10.038 10.038 0 0033.57 5.03a9.95 9.95 0 00-2.714 5.103 9.928 9.928 0 00.583 5.745 9.973 9.973 0 003.685 4.459 10.03 10.03 0 005.548 1.674c1.858.01 3.683-.487 5.28-1.436l2.916 2.918 2.361-2.353-2.787-2.788a10.058 10.058 0 002.232-6.388zm-7.215 6.14c-.864.432-1.82.65-2.787.636-3.779 0-6.224-2.988-6.224-6.776s2.445-6.776 6.224-6.776c3.78 0 6.224 3.012 6.224 6.776a7.647 7.647 0 01-.969 3.788l-2.858-2.847-2.36 2.353.13.13 2.62 2.716zm17.915-2.881c0 2.223-1.276 3.905-3.177 3.905-1.677 0-2.527-1.176-2.527-3.435V7.906h-3.413v8.234c0 3.812 1.453 5.882 4.724 5.882a5.1 5.1 0 004.428-2.988v2.577h3.437V7.906h-3.472v7.317zm9.164-7.317h-3.413v13.716h3.413V7.906zm0-5.541h-3.413v3.4h3.413v-3.4zm10.12 5.129a5.103 5.103 0 00-2.626.874 5.074 5.074 0 00-1.779 2.114V7.906h-3.365v13.716H76.3v-7.329c0-2.246 1.18-3.893 3.14-3.893 1.678 0 2.788 1.176 2.788 3.423v7.8h3.436v-8.235c0-3.812-1.736-5.882-5.007-5.882m10.451-3.612H87.72v14.329a3.046 3.046 0 00.881 2.53 3.072 3.072 0 002.544.87h3.767v-2.965h-2.81a.854.854 0 01-.912-.553.842.842 0 01-.045-.377V10.67h3.767V7.847H91.11V3.894z"
        />
        <path
          fill="#000"
          d="M102.634 7.459a7.355 7.355 0 00-4.14 1.109 7.314 7.314 0 00-2.81 3.225 7.28 7.28 0 001.428 8.043 7.359 7.359 0 008.02 1.698 7.33 7.33 0 003.333-2.687 7.285 7.285 0 001.255-4.083 6.948 6.948 0 00-1.955-5.181 6.993 6.993 0 00-5.131-2.124zm0 11.528c-2.007 0-3.637-1.482-3.637-4.223 0-2.741 1.63-4.259 3.637-4.259 2.008 0 3.661 1.506 3.661 4.259s-1.618 4.223-3.661 4.223zm14.431-16.575l-7.475 19.21h3.838l1.759-4.917h8.019l1.783 4.918h3.838l-7.499-19.211h-4.263zm-.862 11.47l2.976-8.235 3.011 8.235h-5.987zm21.304-6.388a5.14 5.14 0 00-2.63.876 5.115 5.115 0 00-1.787 2.112l.059-2.576h-3.353v13.716h3.412v-7.329c0-2.246 1.181-3.893 3.142-3.893 1.677 0 2.787 1.176 2.787 3.423v7.8h3.436v-8.235c0-3.812-1.736-5.882-5.007-5.882m17.431 2.729a4.99 4.99 0 00-4.512-2.741c-3.838 0-6.424 3.364-6.424 7.294 0 3.929 2.586 7.246 6.424 7.246a5.26 5.26 0 004.571-2.776v2.353h3.412V2.412h-3.412l-.059 7.823zm-3.661 8.835a3.99 3.99 0 01-2.779-1.355 3.969 3.969 0 01-.965-2.927 4.016 4.016 0 012.213-3.923 4.05 4.05 0 011.531-.418 4.092 4.092 0 012.782 1.396 4.058 4.058 0 01.985 2.945 4 4 0 01-2.24 3.881c-.478.233-.997.37-1.527.401zm15.458-11.611c-3.779 0-6.035 2.223-6.472 4.258l3.413.6a2.981 2.981 0 012.976-2.058 2.596 2.596 0 011.994.799 2.563 2.563 0 01.699 2.024v1.741a5.848 5.848 0 00-4.098-1.423c-2.893 0-5.149 1.611-5.149 4.246 0 2.636 1.901 4.365 4.842 4.365a5.136 5.136 0 004.487-2.188l-.082 1.752h3.424v-8.786c0-3.4-2.291-5.376-6.034-5.376v.046zm-.39 12.07c-1.877 0-2.751-.93-2.751-1.918 0-.988.85-2.012 2.751-2.012 1.713 0 3.059.742 3.059 1.788 0 1.047-1.323 2.142-3.059 2.142zm16.285-11.847a4.733 4.733 0 00-2.776 1.042 4.698 4.698 0 00-1.605 2.487h-.094l.188-3.365h-3.412v13.717h3.412v-5.682c0-3.87 2.043-4.964 4.027-4.964H184V7.764a7.75 7.75 0 00-1.323-.106"
        />
      </g>
      <defs>
        <clipPath id="clip0_611_1811">
          <path fill="#fff" d="M0 0H184V25H0z" transform="translate(0 .459)" />
        </clipPath>
      </defs>
    </svg>
  );
}
